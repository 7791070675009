
import { Component, Prop, Vue } from "vue-property-decorator"
interface IRTag {
  name: string
  icon: string
}
@Component
export default class ContractSummaryCard extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly subtitle?: string
  @Prop({ required: true }) readonly description!: string
  @Prop({ required: false }) readonly tags?: IRTag[]
  @Prop({ required: true }) readonly status?: string
  @Prop({ required: false, default: false }) active!: boolean

  get contractState() {
    return this.status === "approved" ? "pending" : this.status
  }
}
